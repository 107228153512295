import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>MOUTH CANCER</h2>
      <h3>What is it?</h3>
      <p>Mouth cancer is a dangerous abnormal growth that can affect any part of the mouth.</p>
      <p>What are the signs of mouth cancer?</p>
      <p>Mouth cancer can appear as:</p>
      <ul>
        <li>an, often painless, ulcer that fails to clear up after about  two weeks</li>
        <li>a growth or swelling which  has been  present for more  than about  two weeks</li>
        <li>sometimes as a white  or red patch  in the mouth.</li>
      </ul>
      <p>Most things like this will not turn  out to be cancer, but if you have these  signs  they  must  be investigated by going to your  dentist immediately.</p>
      <h3>Am I at risk from mouth cancer?</h3>
      <p>If you use tobacco (smoked or chewed) and drink too much alcohol, you will greatly increase your risk of getting mouth  cancer. If you do both together,  your chances of getting it are even greater still.</p>
      <p>Prolonged exposure to sunlight can increase your risk of lip and skin cancer.</p>
      <p>The chances of getting mouth  cancer are greater for people aged over 40 years,  but younger people can get it too.</p>
      <p>You are more  at risk of cancer  if you don't  eat a healthy diet.</p>
      <p>How can mouth cancer be detected?</p>
      <p>Your dentist  can spot mouth  cancer early. If it is detected early, the chances of a cure are very good. At the moment around half f the 4,000 people diagnosed with mouth cancer every  year in the UK die of it because it is discovered too late.</p>
      <h3>What will my dentist do?</h3>
      <p>you should expect  your dentist  to:</p>
      <ul>
        <li>look at your face and neck</li>
        <li>feel under  your jaw and down  your neck, and</li>
        <li>examine the inside of your mouth  with a small mirror, looking at your lips, cheeks, tongue,  the roof of your mouth  and your throat.  Your dentist can see parts of your mouth that you can't easily see or feel yourself. Your dentist may notice something in your mouth  that needs  to be monitored or needs  to be seen by a specialist.</li>
      </ul>
      <h3>What are the benefits of being regularly checked?</h3>
      <p>Regular examinations mean that  your dentist can  spot problems early  - and these  include mouth cancer.</p>
      <h3>How can I prevent mouth cancer?</h3>
      <p>By following a healthy diet  with five to seven portions of fruit and vegetables each  day, you can help  to prevent mouth cancer as well as many other  cancers and diseases. (A portion is roughly a handful.)</p>
      <p>Do not use tobacco at all and drink  moderately - that is, keep your weekly intake to below 14 units  for women and  21 units for men.(One  unit is roughly equal  to a single measure of spirit,  a half pint of beer  or a small glass  of wine.)</p>
    </LightboxLayout>
  )
}

export default Page